const items = document.querySelectorAll(".customers-text .text-slide")
const buttons = document.querySelectorAll(".customers-text .slider")
const buttonsImg = document.querySelectorAll(".customers-image ul li")
const imgs = document.querySelectorAll(".customers-image ul li img")

let oldIndex = 1
let isWaiting = false

const handleClick = (currentIndex) => {
  if (isWaiting || currentIndex === oldIndex) return
  isWaiting = true

  const come = currentIndex > oldIndex ? "comeRight" : "comeLeft"
  const leave = currentIndex > oldIndex ? "leaveLeft" : "leaveRight"

  items[currentIndex].classList.add(come)
  items[oldIndex].classList.add(leave)

  setTimeout(() => {
    items.forEach((item) => {
      item.classList.remove(come)
      item.classList.remove(leave)
    })
  }, 500)

  buttons.forEach((button) => button.classList.remove("active"))
  buttonsImg.forEach((button) => button.classList.remove("active"))
  imgs.forEach((img) => img.classList.remove("active"))

  items[currentIndex].classList.add("active")
  buttons[currentIndex].classList.add("active")
  buttonsImg[currentIndex].classList.add("active")
  imgs[currentIndex].classList.add("active")

  setTimeout(() => {
    items[oldIndex].classList.remove("active")
    oldIndex = currentIndex
    isWaiting = false
  }, 500)
}

buttons.forEach((button, i) =>
  button.addEventListener("click", () => handleClick(i))
)

imgs.forEach((button, i) =>
  button.addEventListener("click", () => handleClick(i))
)
